import {createApp} from "vue";
import App from "./App.vue";
import router from "@js/routes/router";

import 'uno.css'

import {createPinia} from "pinia";
import Toast, {type PluginOptions, POSITION} from "vue-toastification";
import {isRtl} from "@lang/t";

import FloatingVue from 'floating-vue'
import Footer from '@components/layouts/Footer.vue'
import Card from '@components/Card.vue'
import Submit from '@components/Submit.vue'
import Modal from '@components/Modal.vue'
import Form from '@components/Form.vue'
import IconBtn from '@components/buttons/IconBtn.vue'
import Loading from '@components/Loading.vue'
import {Icon} from "@iconify/vue";
import PrimaryTable from "@components/tables/PrimaryTable.vue";

import SW from "@js/workers/SW";
import {useErrorStore} from "@js/stores/Error";
import paddle from "@js/models/Paddle/Paddle";
import {AxiosError} from "axios";

const app = createApp(App);

app
    .use(createPinia())
    .use(FloatingVue)
    .use(router)
    .component('Footer', Footer)
    .component('Card', Card)
    .component('Submit', Submit)
    .component('Modal', Modal)
    .component('Form', Form)
    .component('Icon', Icon)
    .component('Loading', Loading)
    .component('IconBtn', IconBtn)
    .component('Multiselect', () => import('@components/forms/Multiselect.vue'))
    .component('PrimaryTable', PrimaryTable)

// Vue error handler using app.config.errorHandler
app.config.errorHandler = (err, instance, info) => {
    if (err instanceof Error) {
        useErrorStore().addError(err, info || 'Vue error', window.location.href.toString());
    }
    throw err;
};

// Global JavaScript error handler using window.onerror
window.onerror = function(message, source, lineno, colno, error) {
    let toRecordError = error instanceof Error ? error : (
        new Error(typeof message === "string" ? message : JSON.stringify(message))
    );

    useErrorStore().addError(toRecordError,'Global JavaScript error',window.location.href.toString());

    throw error;
};

window.onunhandledrejection = function(event) {
    let error;

    // silence all axios errors, that is because it may be unauthorized access, unauth, or validation
    if(event.reason instanceof AxiosError) {
        console.error(event);
        return;
    }

    if (event.reason instanceof Error) {
        error = event.reason;
    } else {
        error = new Error(
            typeof event.reason === 'object'
                ? JSON.stringify(event.reason)
                : String(event.reason)
        );
    }

    useErrorStore().addError(error,'Promise Rejection',window.location.href.toString());
    console.error(event);
};

const options: PluginOptions = {
    position:  isRtl ? POSITION.TOP_RIGHT : POSITION.TOP_LEFT,
    transition: "Vue-Toastification__fade",
    rtl: isRtl,
    showCloseButtonOnHover: true,
    // timeout: false,
    containerClassName: 'ltr',
};

app.use(Toast, options);

if(SW.isSupported()) {
    SW.register();
}
app.mount("#app");

paddle.init();
