<template>
    <div class="relative" :class="wrapperClasses">
        <input v-bind="$attrs" ref="input" type="text" class="ltr:pr-6 rtl:pl-6 w-full" dir="auto">
        <icon :icon="icon" class="absolute ltr:right-2 rtl:left-2 my-auto top-0 bottom-0 text-xl"
              :class="iconClasses" @click="emits('icon-click', input.value)"/>
        <slot />
    </div>
</template>

<script setup lang="ts">
import {Icon} from "@iconify/vue";
import {ref} from "vue";

defineOptions({
    inheritAttrs: false
})

interface Props {
    icon: string,
    iconClasses?: string
    wrapperClasses?: string
}
defineProps<Props>()

const input = ref()
const emits = defineEmits(['icon-click'])
</script>

<style scoped lang="scss"></style>
