import type {PopupBroadcastNotification} from "./websocket";
import {echo} from "./websocket"
import {useUser} from "@js/stores/User";
import {TYPE, useToast} from "vue-toastification";
import NotificationWithTitle from "@components/NotificationWithTitle.vue";
import notificationSound from '@js/assets/sounds/notification.wav'
import {NotificationsEnum} from "@js/types/generated";

export type PopupNotificationType = {
    title?: string,
    type?: TYPE
    content: string,
    icon?: string,
    actions?:[
        {
            title: string,
            action: string
            icon?: string,
        }
    ]
}

/**
 * How to use: just add function with the name of the notification, it will be automatically registered
 */
class PopupNotifications {

    public notificationsToSuppress: string[] = [];

    public TYPES = {
        IncomingMessage: 'IncomingMessage',
        RemovedMessage: 'RemovedMessage'
    }

    constructor() {}

    suppress(notification: string) {
        this.notificationsToSuppress.push(notification);
    }

    init()
    {
        for (let name in NotificationsEnum) {
            if (isNaN(Number(name))) {
                echo.private(this.getUserChannel())
                    .listen('.' + name,(notification: PopupBroadcastNotification) => {
                        this.popupNotification(notification, name as keyof typeof NotificationsEnum)
                    })
            }
        }
    }

    popupNotification(notification: PopupBroadcastNotification, name: keyof typeof NotificationsEnum)
    {
        let user = useUser().auth
        let preference = user.notification_preferences[notification.account_id]?.find((preference) => preference.name === name);

        if(preference?.popup) {
            if(!this.notificationsToSuppress.includes(name)) {
                this.triggerNotification(notification.popup)
            }
        }
    }

    reset()
    {
        echo.leave(this.getUserChannel())
        this.notificationsToSuppress = []

        this.init();
        return this;
    }

    getUserChannel()
    {
        let user = useUser().auth

        return `users.${user.id}`;
    }

    playAudio()
    {
        let audio = new Audio(notificationSound);
        audio.play()
            .catch(() => {
                // do nothing in this case
            });
    }

    triggerNotification(notification: PopupNotificationType|undefined)
    {
        if (notification){
            this.playAudio();
            let toast = useToast();
            toast({
                component: NotificationWithTitle,
                props: {
                    title: notification.title,
                    content: notification.content,
                    icon: notification.icon,
                    actions:notification.actions
                }
            }, {
                icon: false,
                type: notification.type ?? TYPE.INFO,
                toastClassName:'items-start',
                closeButtonClassName:'mt-1 '
            });
        }
    }
}

export default new PopupNotifications();
